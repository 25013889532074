<template>
    <AppModal
        :value="!!value"
        variant="bordered"
        @input="$emit('input', false)"
    >
        <template #title>
            <translate>Evaluatie</translate>
        </template>

        <AppEducationPlanEvaluationForm
            v-if="insights.length === 1"
            ref="evaluations"
            :insight-id="insights[0].id"
        />
        <template v-else-if="insights">
            <AppInputRadioGroup
                v-if="type === 'education_plan'"
                v-model="evaluationType"
                :disabled="loading > 0"
                hide-details
                class="u-margin-bottom-medium"
            >
                <AppInputRadio
                    :label="$gettext('Tussentijdse evaluatie')"
                    value="interim"
                />
                <AppInputRadio
                    :label="$gettext('Evalueren en inzicht afronden')"
                    value="final"
                />
            </AppInputRadioGroup>

            <AppHeader>
                <AppTitle v-translate as="h2" size="xsmall">
                    Evaluatie
                </AppTitle>
            </AppHeader>
            <AppFormGroup>
                <AppInputCheckbox
                    v-model="useSameEvaluation"
                    :label="
                        $gettext('Evaluatie is bij elke leerling hetzelfde')
                    "
                />
            </AppFormGroup>

            <AppEducationPlanEvaluationFormMultiple
                v-if="useSameEvaluation"
                ref="evaluations"
                :bulk-id="insights[0] ? insights[0].bulkId : ''"
                :is-interim="evaluationType === 'interim'"
            />
            <template v-else>
                <AppEducationPlanEvaluationForm
                    v-for="insight in insights"
                    :key="insight.id"
                    ref="evaluations"
                    :title="insight.student.fullName"
                    :insight-id="insight.id"
                    :is-interim="evaluationType === 'interim'"
                />
            </template>
        </template>

        <template #footer>
            <AppButtonGroup variant="between">
                <AppLink
                    v-translate
                    as="button"
                    variant="bold font-primary"
                    @click.native="$emit('input', false)"
                >
                    Annuleren
                </AppLink>
                <AppButton
                    variant="secondary large"
                    @click="submitEvaluationForms"
                >
                    {{
                        evaluationType === "interim"
                            ? $gettext("Evalueren")
                            : $gettext("Afronden")
                    }}
                </AppButton>
            </AppButtonGroup>
        </template>
    </AppModal>
</template>

<script>
import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup";
import AppEducationPlanEvaluationForm from "@/components/AppEducationPlanEvaluationForm";
import AppEducationPlanEvaluationFormMultiple from "@/components/AppEducationPlanEvaluationFormMultiple";
import AppFormGroup from "@/components/AppFormGroup";
import AppHeader from "@/components/AppHeader.vue";
import AppInputCheckbox from "@/components/AppInputCheckbox";
import AppInputRadio from "@/components/AppInputRadio.vue";
import AppInputRadioGroup from "@/components/AppInputRadioGroup.vue";
import AppLink from "@/components/AppLink";
import AppModal from "@/components/AppModal";
import AppTitle from "@/components/AppTitle.vue";
import FormSave from "@/mixins/FormSave";
import { mapMutations } from "vuex";

export default {
    name: "AppEducationPlanEvaluationModal",
    components: {
        AppInputRadioGroup,
        AppInputRadio,
        AppTitle,
        AppHeader,
        AppEducationPlanEvaluationFormMultiple,
        AppEducationPlanEvaluationForm,
        AppButton,
        AppButtonGroup,
        AppFormGroup,
        AppInputCheckbox,
        AppLink,
        AppModal
    },
    mixins: [FormSave],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        students: {
            type: Array,
            default: undefined
        },
        insights: {
            type: Array,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            useSameEvaluation: false,
            evaluationType: this.type === "education_plan" ? "interim" : "final"
        };
    },
    methods: {
        ...mapMutations("insightmodal", ["expiredInsight"]),
        submitEvaluationForms() {
            const promises = [];
            const evaluations = Array.isArray(this.$refs.evaluations)
                ? this.$refs.evaluations
                : [this.$refs.evaluations];

            if (evaluations.map(form => !form.formIsValid()).some(x => x)) {
                return;
            }
            evaluations.forEach(form => {
                promises.push(form.formSubmit());
            });

            Promise.all(promises).then(values => {
                const expiredInsightIds = values.reduce((acc, evaluation) => {
                    if (evaluation.data && evaluation.data.evaluateInsight) {
                        evaluation.data.evaluateInsight.insights.forEach(x => {
                            acc.push(x.id);
                        });
                    }

                    return acc;
                }, []);

                expiredInsightIds.forEach(insightId =>
                    this.expiredInsight(insightId)
                );

                // don't close if values has empty arrays
                if (!values.includes(undefined)) {
                    this.$emit("input", false);
                    this.$emit("success");
                }
            });
        }
    }
};
</script>
