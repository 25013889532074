<template>
    <AppModal
        :value="!!value"
        variant="bordered"
        size="smedium"
        @input="$emit('input', false)"
    >
        <template #title>
            <translate>Tussentijdse evaluatie</translate>
        </template>

        <AppEducationPlanEvaluationForm
            ref="evaluation"
            :insight-id="insightId"
            is-interim
        />
        <template #footer>
            <AppButtonGroup variant="between">
                <AppLink
                    v-translate
                    as="button"
                    variant="bold font-primary"
                    @click.native="$emit('input', false)"
                >
                    Annuleren
                </AppLink>
                <AppButton
                    v-translate
                    variant="secondary large"
                    @click="submitEvaluationForm"
                >
                    Evalueren
                </AppButton>
            </AppButtonGroup>
        </template>
    </AppModal>
</template>

<script>
import AppButton from "@/components/AppButton.vue";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppEducationPlanEvaluationForm from "@/components/AppEducationPlanEvaluationForm.vue";
import AppLink from "@/components/AppLink.vue";
import AppModal from "@/components/AppModal";
import FormSave from "@/mixins/FormSave";
import { mapMutations } from "vuex";

export default {
    name: "AppEducationPlanInterimEvaluationModal",
    components: {
        AppEducationPlanEvaluationForm,
        AppButton,
        AppLink,
        AppButtonGroup,
        AppModal
    },
    mixins: [FormSave],
    props: {
        value: {
            type: Boolean,
            default: false
        },
        insightId: {
            type: String,
            required: true
        }
    },
    methods: {
        ...mapMutations("insightmodal", ["updatedInsight"]),
        submitEvaluationForm() {
            if (!this.$refs.evaluation.formIsValid()) {
                return;
            }
            Promise.resolve(this.$refs.evaluation.formSubmit()).then(value => {
                this.updatedInsight(this.insightId);

                if (value) {
                    this.$emit("input", false);
                    this.$emit("success");
                }
            });
        }
    }
};
</script>
