<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";
import { defineComponent } from "vue";

export default defineComponent({
    name: "AppBadgeGroup",
    setup(props) {
        const { antlerClass } = antlerComponent("badge-group", props);

        return {
            antlerClass
        };
    }
});
</script>

<style lang="sass">
.badge-group
    display: inline-flex
    align-items: center
    flex-wrap: wrap
    gap: 8px
</style>
