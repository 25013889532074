<template>
    <AppModal
        :value="!!value"
        variant="bordered"
        @input="$emit('input', false)"
    >
        <template #title>
            <translate>Inzichten</translate>
        </template>

        <AppStudentList
            v-if="allInsightsByBulkId"
            class="u-margin-bottom-small"
        >
            <AppStudentListItem
                v-for="(insight, index) in allInsightsByBulkId"
                :key="insight.id"
                :student="insight.student"
            >
                <AppButton
                    v-translate
                    icon="eye"
                    icon-size="regular"
                    variant="link medium link-underline"
                    @click="showInsightModal(index)"
                >
                    Bekijk inzicht
                </AppButton>

                <AppInsightDetailModal
                    v-model="showInsightDetailModals[index]"
                    :insight-id="insight.insightId"
                />
            </AppStudentListItem>
        </AppStudentList>

        <template #footer>
            <AppButtonGroup variant="between">
                <AppLink
                    v-translate
                    as="button"
                    variant="bold font-primary"
                    @click.native="$emit('input', false)"
                >
                    Annuleren
                </AppLink>
                <AppButton
                    v-if="!isExpired"
                    v-translate
                    variant="secondary large"
                    @click="showEducationPlanFinishModal = true"
                >
                    Evalueren
                </AppButton>
            </AppButtonGroup>

            <AppEducationPlanEvaluationModal
                v-model="showEducationPlanFinishModal"
                :insights="allInsightsByBulkId"
                :type="allInsightsByBulkId[0].type"
            />
        </template>
    </AppModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AppButton from "@/components/AppButton";
import AppButtonGroup from "@/components/AppButtonGroup";
import AppEducationPlanEvaluationModal from "@/components/AppEducationPlanEvaluationModal";
import AppInsightDetailModal from "@/components/AppInsightDetailModal.vue";
import AppLink from "@/components/AppLink";
import AppModal from "@/components/AppModal";
import AppStudentList from "@/components/AppStudentList";
import AppStudentListItem from "@/components/AppStudentListItem";
import { cloneDeep } from "lodash";
import { consoleError } from "@/console";
import gql from "graphql-tag";

const insightFields = `
    id
    type
    bulkId
    insightId
    student {
        id
        fullName
        profilePicture {
            filename
            url(
                options: {
                    geometry: "100x100"
                    asynchronous: true
                    crop: "center"
                }
            )
        }
    }
`;

export default {
    name: "AppEditMultipleEducationPlanStudentsModal",
    components: {
        AppInsightDetailModal,
        AppEducationPlanEvaluationModal,
        AppStudentListItem,
        AppStudentList,
        AppLink,
        AppButton,
        AppButtonGroup,
        AppModal
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        bulkId: {
            type: String,
            required: true
        },
        groupId: {
            type: String,
            required: true
        },
        isExpired: Boolean
    },
    data() {
        return {
            allInsightsByBulkId: [],
            showEducationPlanFinishModal: false,
            showInsightDetailModals: []
        };
    },
    computed: {
        ...mapGetters("insightmodal", [
            "createdInsights",
            "updatedInsights",
            "expiredInsights",
            "deletedInsights"
        ])
    },
    apollo: {
        allInsightsByBulkId: {
            query: gql`
                query(
                    $bulkId: UUID!
                    $groupId: ID!
                ) {
                    allInsights(
                        bulkId: $bulkId,
                        groupId: $groupId
                        noCopies: true
                    ) {
                        edges {
                            node {
                                ... on Insight {
                                    ${insightFields}
                                }
                            }
                        }
                    }
                }
            `,
            variables() {
                return {
                    bulkId: this.bulkId,
                    groupId: this.groupId
                };
            },
            update(data) {
                if (data.allInsights) {
                    return data.allInsights.edges
                        .map(x => {
                            const node = cloneDeep(x.node);
                            node.type = node.type.toLowerCase();
                            return node;
                        })
                        .sort((a, b) => {
                            const nameA = a.student.fullName.toLowerCase();
                            const nameB = b.student.fullName.toLowerCase();
                            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
                        });
                } else {
                    return this.allInsights;
                }
            },
            error(e) {
                consoleError(e, this);
                this.showMessage({
                    message: this.$gettext(
                        "Er is een fout opgetreden bij het ophalen van bulk inzichten."
                    ),
                    type: "error"
                });
            }
        }
    },
    watch: {
        createdInsights() {
            this.refetchInsights();
            this.$emit("input", false);
        },
        expiredInsights() {
            this.refetchInsights();
            this.$emit("input", false);
        },
        updatedInsights() {
            this.refetchInsights();
            this.$emit("input", false);
        },
        deletedInsights() {
            this.refetchInsights();
            this.$emit("input", false);
        },
        allInsightsByBulkId() {
            this.showInsightDetailModals = this.allInsightsByBulkId.map(
                () => false
            );
        }
    },
    methods: {
        ...mapMutations("insightmodal", ["openModal"]),
        ...mapActions("message", ["showMessage"]),
        refetchInsights() {
            this.$apollo.queries.allInsightsByBulkId.refetch();
        },
        showInsightModal(index) {
            this.showInsightDetailModals = this.showInsightDetailModals.map(
                (value, i) => i === index
            );
        }
    }
};
</script>
