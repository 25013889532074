<template>
    <div :class="antlerClass()">
        <div class="loader-spinner" />
        <span v-if="text" class="loader-text">{{ text }}</span>
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppLoader",
    props: {
        text: {
            type: String,
            default: ""
        },
        variant: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("loader", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.loader
    display: flex
    align-items: center

.loader-spinner
    display: inline-flex
    width: 16px
    height: 16px

    border:
        color: $color-primary transparent transparent transparent
        radius: $global-border-radius-rounded
        style: solid
        width: 2px
    content: ""

    animation: spinning 2000ms $global-transition-animation
    animation-iteration-count: infinite

.loader-text
    margin-left: $spacing-small

// sizes

.loader--large
    .loader-text
        @include font-size(18px)

    .loader-spinner
        width: 24px
        height: 24px

// variants

.loader--large-orange
    .loader-spinner
        width: 30px
        height: 30px

        border:
            width: 1px
            color: $color-yellow transparent transparent transparent

.loader--centered
    justify-content: center
</style>
