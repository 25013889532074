<template>
    <AppForm ref="form" lazy-validation @submit.prevent="formSubmit">
        <AppFormGroup>
            <AppHeader v-if="title" size="small">
                <AppTitle size="xxsmall">
                    {{ title }}
                </AppTitle>
            </AppHeader>
            <!-- prettier-ignore -->
            <AppInputTextarea
                v-model="formValues.evaluation"
                :error-messages="formErrors.evaluation"
                :label="isInterim ? $gettext('Vul een tussentijdse opmerking in') : $gettext('Vul een opmerking in waarom dit is afgerond')"
                required
                :rules="[
                    v =>
                        !!v ||
                        $gettext('Dit veld is verplicht')
                ]"
            />
        </AppFormGroup>
    </AppForm>
</template>

<script>
import AppForm from "@/components/AppForm";
import AppFormGroup from "@/components/AppFormGroup";
import AppHeader from "@/components/AppHeader";
import AppInputTextarea from "@/components/AppInputTextarea";
import AppTitle from "@/components/AppTitle";
import FormSave from "@/mixins/FormSave";
import gql from "graphql-tag";
import { mapActions } from "vuex";

export default {
    name: "AppEducationPlanEvaluationForm",
    components: {
        AppFormGroup,
        AppTitle,
        AppHeader,
        AppInputTextarea,
        AppForm
    },
    mixins: [FormSave],
    props: {
        insightId: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: ""
        },
        isInterim: Boolean
    },
    data() {
        return {
            formValues: {
                evaluation: ""
            },
            emptyFormErrors: {
                evaluation: []
            }
        };
    },
    computed: {
        numericId() {
            return atob(this.insightId).split(":")[1];
        }
    },
    methods: {
        ...mapActions("message", ["showMessage"]),
        getMutation() {
            const input = {
                id: this.numericId,
                evaluation: this.formValues.evaluation,
                interimEvaluation: this.isInterim
            };

            return {
                mutation: gql`
                    mutation evaluateInsight(
                        $input: InsightEvaluationMutationInput!
                    ) {
                        evaluateInsight(input: $input) {
                            insights {
                                id
                                evaluation
                                evaluations {
                                    edges {
                                        node {
                                            id
                                            evaluation
                                            created
                                            createdTeacher {
                                                id
                                                fullName
                                            }
                                        }
                                    }
                                }
                                expired
                                bulkId
                                modifiedTeacher {
                                    id
                                    fullName
                                }
                                modified
                            }
                            errors {
                                field
                                messages
                            }
                        }
                    }
                `,
                variables: { input }
            };
        },
        formSuccess() {
            this.showMessage({
                message: this.$gettext("De evaluatie is succesvol opgeslagen."),
                type: "success"
            });
        }
    }
};
</script>
