<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppStudentLinks",
    setup() {
        const { antlerClass } = antlerComponent("student-links");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.student-links
    padding: $spacing-xsmall 10px

    background-color: rgba($color-blue-lighter, .5)
    border-radius: $global-border-radius-big

    .student-link
        margin: 4px $spacing-xsmall
</style>
