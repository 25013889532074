<template>
    <div
        :class="
            antlerClass('', {
                'with-icon': icon
            })
        "
    >
        <AppIcon v-if="icon" :name="icon" size="xsmall" />
        <slot />
    </div>
</template>

<script>
import AppIcon from "@/components/AppIcon.vue";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppBadge",
    components: { AppIcon },
    props: {
        variant: {
            type: String,
            default: "default"
        },
        icon: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("badge", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.badge
    display: inline-flex
    align-items: center
    gap: 4px
    padding: 4px $spacing-small

    @include font-size(13px, 16px)
    font:
        family: $primary-font-family
        weight: $strong-weight
    white-space: nowrap

    border-radius: $global-border-radius-rounded

    cursor: default

.badge--default
    color: $color-blue

    background-color: $color-blue-lighter

.badge--expired
    color: $color-blue

    background-color: $color-blue-lighter

.badge--absence
    color: #fff

    background-color: $color-yellow

.badge--appointment
    color: #fff

    background-color: $color-green

.badge--event
    color: #fff

    background-color: $color-purple

.badge--concern
    color: #fff

    background-color: $color-orange

.badge--educational_level
    color: #fff

    background-color: $color-yellow

.badge--education_plan
    color: #fff

    background-color: $color-red

.badge--contact_moment
    color: #fff

    background-color: $color-blue

.badge--diagnosis
    color: #fff

    background-color: $color-green-dark
</style>
