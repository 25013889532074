<template>
    <AppLink
        :class="antlerClass()"
        :to="{
            name: 'student_insight',
            params: { id: studentId }
        }"
    >
        {{ studentName }}
    </AppLink>
</template>

<script>
import AppLink from "@/components/AppLink";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppStudentLink",
    components: { AppLink },
    props: {
        studentName: {
            type: String,
            required: true
        },
        studentId: {
            type: String,
            required: true
        }
    },
    setup() {
        const { antlerClass } = antlerComponent("student-link");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.student-link
    display: inline-flex

    @include font-size(14px)
</style>
