<template>
    <div :class="antlerClass()">
        <AppAvatar
            v-if="student.profilePicture"
            :image="student.profilePicture.url || undefined"
            class="student-list__item-avatar"
            size="small"
        />

        <AppTitle size="xxsmall">
            {{ student.fullName }}
        </AppTitle>

        <div v-if="$slots.default" class="student-list__item-options">
            <slot />
        </div>
    </div>
</template>

<script>
import AppAvatar from "@/components/AppAvatar";
import AppTitle from "@/components/AppTitle";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppStudentListItem",
    components: { AppTitle, AppAvatar },
    props: {
        student: {
            type: Object,
            required: true
        }
    },
    setup() {
        const { antlerClass } = antlerComponent("student-list__item");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.student-list__item
    display: flex
    align-items: center
    padding:
        top: $spacing-small
        bottom: $spacing-small

    &:first-child
        padding-top: 0

    &:last-child
        padding-bottom: 0

.student-list__item-avatar
    margin-right: $spacing-small

.student-list__item-options
    margin-left: auto
</style>
