<template>
    <AppModal
        :value="!!value"
        variant="bordered"
        size="medium"
        class="insight-detail"
        @input="$emit('input', false)"
    >
        <template #title>
            <translate>Inzicht</translate>
        </template>

        <AppLoader v-if="!insight" />
        <div v-else data-testid="insight-detail">
            <header class="insight-detail__header">
                <AppTitle v-if="insight.student" as="h3" size="small">
                    {{ insight.student.fullName }}
                </AppTitle>

                <AppBadgeGroup>
                    <AppBadge v-if="insight.type" :variant="insight.type">
                        {{ insight.typeDisplay }}
                    </AppBadge>

                    <template v-if="!isExpired">
                        <AppBadge
                            v-if="insight.encouraging === true"
                            key="encouraging"
                            v-translate
                        >
                            Bevorderend
                        </AppBadge>
                        <AppBadge
                            v-else-if="insight.encouraging === false"
                            key="not-encouraging"
                            v-translate
                        >
                            Belemmerend
                        </AppBadge>
                    </template>

                    <AppBadge
                        v-if="isExpired"
                        v-translate
                        variant="expired"
                        icon="check-small"
                    >
                        <!-- prettier-ignore -->
                        {{ insight.type === "education_plan" ? "Afgerond" : "Vervallen" }}
                    </AppBadge>
                </AppBadgeGroup>

                <div v-if="insight.important" class="insight-detail__important">
                    <AppIcon color="red" name="flag" />
                    <translate>Belangrijk</translate>
                </div>
            </header>

            <template
                v-if="
                    subjectsDisplay ||
                        insight.dateSpecific ||
                        dateWeekdaysDisplay ||
                        insight.type === 'absence'
                "
            >
                <AppDivider color="dark" />

                <AppList gap="small">
                    <AppListItem
                        v-if="subjectsDisplay"
                        icon="subject"
                        variant="bold"
                        class="t-size-small"
                    >
                        {{ subjectsDisplay }}
                    </AppListItem>

                    <AppListItem
                        v-if="insight.dateSpecific"
                        icon="calendar"
                        variant="bold"
                        class="t-size-small"
                    >
                        <span>{{ insight.dateSpecific | moment("LL") }}</span>
                    </AppListItem>
                    <AppListItem
                        v-else-if="dateWeekdaysDisplay"
                        icon="calendar"
                        variant="bold"
                        class="t-size-small"
                    >
                        <translate
                            :translate-params="{
                                weekdays: dateWeekdaysDisplay
                            }"
                        >
                            Elke %{weekdays}
                        </translate>
                    </AppListItem>
                    <AppListItem
                        v-else-if="insight.type === 'absence' && insight.date"
                        icon="calendar"
                        variant="bold"
                        class="t-size-small t-capitalize"
                    >
                        <span>{{ insight.date | moment("dddd LL") }}</span>
                    </AppListItem>
                </AppList>
            </template>

            <template
                v-if="
                    insight.goal ||
                        insight.method ||
                        insight.insight ||
                        insight.title ||
                        insight.type === 'absence'
                "
            >
                <AppDivider color="dark" />

                <div class="insight-detail__info">
                    <div v-if="insightTitle" class="insight-detail__info-item">
                        <AppTitle v-translate size="xxsmall">
                            {{ insightTitleLabel }}
                        </AppTitle>
                        <AppArticle>
                            <p>
                                {{ insightTitle }}
                            </p>
                        </AppArticle>
                    </div>

                    <div
                        v-if="insight.type === 'absence'"
                        class="insight-detail__info-item"
                    >
                        <AppTitle v-translate size="xxsmall">
                            {{ $gettext("Duratie") }}
                        </AppTitle>
                        <AppArticle>
                            <p>
                                {{ insight.absenceDurationDisplay }}
                            </p>
                        </AppArticle>
                    </div>

                    <div
                        v-if="insight.insight"
                        class="insight-detail__info-item"
                    >
                        <AppTitle v-translate size="xxsmall">
                            {{
                                insight.type === "absence"
                                    ? "Opmerking"
                                    : "Inzicht"
                            }}
                        </AppTitle>
                        <AppArticle>
                            <p>
                                {{ insight.insight }}
                            </p>
                        </AppArticle>
                    </div>

                    <div v-if="insight.goal" class="insight-detail__info-item">
                        <AppTitle v-translate size="xxsmall">
                            Doel
                        </AppTitle>
                        <AppArticle>
                            <p>
                                {{ insight.goal }}
                            </p>
                        </AppArticle>
                    </div>

                    <div
                        v-if="insight.method"
                        class="insight-detail__info-item"
                    >
                        <AppTitle v-translate size="xxsmall">
                            Werkwijze
                        </AppTitle>
                        <AppArticle>
                            <p>
                                {{ insight.method }}
                            </p>
                        </AppArticle>
                    </div>
                </div>
            </template>

            <template v-if="insight.attachment && insight.attachment.url">
                <AppDivider color="dark" />

                <AppLink
                    as="a"
                    download
                    target="_blank"
                    icon="attachment"
                    variant="underlined block"
                    :href="insight.attachment.url"
                >
                    {{ insight.attachment.filename }}
                </AppLink>
            </template>

            <template
                v-if="
                    (insight.created && insight.createdTeacher) ||
                        (insight.modified && insight.modifiedTeacher)
                "
            >
                <AppDivider color="dark" />

                <div class="insight-detail__dates">
                    <AppText
                        v-if="insight.created && insight.createdTeacher"
                        v-translate="{
                            created: $moment(insight.created).format('LL'),
                            name: insight.createdTeacher.fullName
                        }"
                        as="div"
                        color="blue-shade-dark"
                        size="small"
                    >
                        Aangemaakt op %{created} door %{name}
                    </AppText>
                    <AppText
                        v-if="insight.modified && insight.modifiedTeacher"
                        v-translate="{
                            modified: $moment(insight.modified).format('LL'),
                            name: insight.modifiedTeacher.fullName
                        }"
                        as="div"
                        color="blue-shade-dark"
                        size="small"
                    >
                        Laatst bijgewerkt op %{modified} door %{name}
                    </AppText>
                </div>
            </template>

            <template
                v-if="
                    insight.evaluation ||
                        (insight.evaluations && insight.evaluations.length)
                "
            >
                <AppDivider color="dark" />

                <div class="insight-detail__evaluations">
                    <template v-if="insight.evaluations.length">
                        <div
                            v-for="evaluation in insight.evaluations"
                            :key="evaluation.id"
                            class="insight-evaluation"
                        >
                            <AppHeader size="small">
                                <AppTitle
                                    v-translate
                                    as="h4"
                                    class="insight-evaluation__title"
                                >
                                    Tussentijdse evaluatie
                                </AppTitle>
                            </AppHeader>

                            <div class="insight-evaluation__body">
                                {{ evaluation.evaluation }}
                            </div>

                            <div class="insight-evaluation__footer">
                                <AppText
                                    v-translate="{
                                        created: $moment(
                                            evaluation.created
                                        ).format('LL'),
                                        name: evaluation.createdTeacher.fullName
                                    }"
                                    as="div"
                                    color="blue-shade-dark"
                                    size="small"
                                >
                                    Aangemaakt op %{created} door %{name}
                                </AppText>
                            </div>
                        </div>
                    </template>

                    <div v-if="insight.evaluation" class="insight-evaluation">
                        <AppHeader size="small">
                            <AppTitle
                                v-translate
                                as="h4"
                                class="insight-evaluation__title"
                            >
                                Afrondende evaluatie
                            </AppTitle>
                        </AppHeader>

                        <div class="insight-evaluation__body">
                            {{ insight.evaluation }}
                        </div>

                        <div
                            v-if="insight.expired"
                            class="insight-evaluation__footer"
                        >
                            <AppText
                                as="div"
                                color="blue-shade-dark"
                                size="small"
                            >
                                {{ $moment(insight.expired).format("LL") }}
                            </AppText>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="isAdmin || isAbsence">
                <AppDivider color="dark" />

                <AppButton
                    variant="link with-icon"
                    class="t-color-red"
                    icon="trash"
                    icon-size="regular"
                    :disabled="loading > 0 || !onLine"
                    @click="confirmDelete"
                >
                    <translate
                        v-if="insight.typeDisplay"
                        :translate-params="{
                            label: insight.typeDisplay
                        }"
                    >
                        %{label} verwijderen
                    </translate>
                </AppButton>
            </template>
        </div>

        <template v-if="insight.type !== 'diagnosis' || isAdmin" #footer>
            <AppButtonGroup variant="between">
                <AppButton
                    icon="edit-small"
                    color="white"
                    size="medium"
                    :disabled="loading > 0 || !onLine"
                    :loading="loading > 0"
                    data-testid="edit-insight-button"
                    @click="openModal({ insightId: insightId })"
                >
                    <translate>Bewerken</translate>
                </AppButton>

                <AppButton
                    v-if="
                        insight.expired == null &&
                            insight.type === 'education_plan'
                    "
                    icon="plus-small"
                    color="white"
                    size="medium"
                    :disabled="loading > 0 || !onLine"
                    @click.prevent="interimEvaluateInsight"
                >
                    <translate>Tussentijds evalueren</translate>
                </AppButton>

                <!-- prettier-ignore -->
                <AppButton
                    v-if="insight.expired == null && !isAbsence"
                    :variant="insight.type === 'education_plan' ? 'secondary evaluate' : 'ghost'"
                    icon="check-small"
                    :size="insight.type === 'education_plan' ? '' : 'medium'"
                    :disabled="loading > 0 || !onLine"
                    @click.prevent="evaluateInsight"
                >
                    <translate
                        v-if="insight.type === 'education_plan'"
                        key="finish"
                    >Afronden</translate>
                    <translate v-else>
                        Afronden / niet meer van toepassing
                    </translate>
                </AppButton>
            </AppButtonGroup>
        </template>

        <AppEducationPlanEvaluationModal
            v-if="insight.type"
            v-model="showEducationPlanFinishModal"
            :insights="[insight]"
            :type="insight.type"
        />

        <AppEducationPlanInterimEvaluationModal
            v-if="insight.type === 'education_plan'"
            v-model="showEducationPlanInterimEvaluationModal"
            :insight-id="insightId"
        />
    </AppModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import AppArticle from "@/components/AppArticle.vue";
import AppBadge from "@/components/AppBadge.vue";
import AppBadgeGroup from "@/components/AppBadgeGroup.vue";
import AppButton from "@/components/AppButton.vue";
import AppButtonGroup from "@/components/AppButtonGroup.vue";
import AppDivider from "@/components/AppDivider.vue";
import AppEducationPlanEvaluationModal from "@/components/AppEducationPlanEvaluationModal.vue";
import AppEducationPlanInterimEvaluationModal from "@/components/AppEducationPlanInterimEvaluationModal.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppLink from "@/components/AppLink.vue";
import AppList from "@/components/AppList.vue";
import AppListItem from "@/components/AppListItem.vue";
import AppLoader from "@/components/AppLoader.vue";
import AppModal from "@/components/AppModal.vue";
import AppText from "@/components/AppText.vue";
import AppTitle from "@/components/AppTitle.vue";
import gql from "graphql-tag";

const insightFields = `
    id
    type
    typeDisplay
    absenceType
    absenceTypeDisplay
    absenceDurationDisplay
    title
    insight
    goal
    method
    date
    important
    attachment {
        filename
        url
    }
    encouraging
    subjects {
        edges {
            node {
                id
                name
            }
        }
    }
    educationPlanSubject {
        id
        name
        parent {
            id
            name
        }
    }
    dateSpecific
    dateWeekdays
    student {
        id
        fullName
    }
    created
    createdTeacher {
        id
        fullName
    }
    modified
    modifiedTeacher {
        id
        fullName
    }
    expired
    evaluation
    evaluations {
        edges {
            node {
                id
                evaluation
                created
                createdTeacher {
                    id
                    fullName
                }
            }
        }
    }
`;

export default {
    name: "AppInsightDetailModal",
    components: {
        AppEducationPlanInterimEvaluationModal,
        AppHeader,
        AppEducationPlanEvaluationModal,
        AppButtonGroup,
        AppLoader,
        AppText,
        AppLink,
        AppArticle,
        AppListItem,
        AppList,
        AppDivider,
        AppIcon,
        AppBadgeGroup,
        AppBadge,
        AppTitle,
        AppModal,
        AppButton
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        insightId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            insight: {},
            showEducationPlanFinishModal: false,
            showEducationPlanInterimEvaluationModal: false,
            loading: 0
        };
    },
    computed: {
        ...mapGetters("auth", ["permissions"]),
        ...mapGetters("online", ["onLine"]),
        numericId() {
            return this.insightId ? atob(this.insightId).split(":")[1] : "";
        },
        isAdmin() {
            return this.permissions === "admin";
        },
        isExpired() {
            return !!this.insight.expired;
        },
        isAbsence() {
            return this.insight.type === "absence";
        },
        subjectsDisplay() {
            if (!this.insight) {
                return "";
            }
            if (
                this.insight.type === "education_plan" &&
                this.insight.educationPlanSubject
            ) {
                if (this.insight.educationPlanSubject.parent) {
                    return `${this.insight.educationPlanSubject.parent.name} | ${this.insight.educationPlanSubject.name}`;
                }

                return this.insight.educationPlanSubject.name;
            }

            return this.insight.subjects && this.insight.subjects.length > 0
                ? this.insight.subjects.map(x => x.name).join(", ")
                : "";
        },
        dateWeekdaysDisplay() {
            return this.insight &&
                this.insight.dateWeekdays &&
                this.insight.dateWeekdays.length > 0
                ? this.insight.dateWeekdays
                      .map(x =>
                          this.$moment()
                              .weekday(x)
                              .format("dddd")
                      )
                      .join(", ")
                : "";
        },
        insightTitle() {
            if (!this.insight) return "";

            if (this.insight.type === "absence") {
                return this.insight.absenceTypeDisplay;
            }

            return this.insight.title;
        },
        insightTitleLabel() {
            if (this.insight.type === "diagnosis") {
                return this.$gettext("Diagnose");
            } else if (this.insight.type === "absence") {
                return this.$gettext("Absentie");
            }

            return this.$gettext("Met wie?");
        }
    },
    apollo: {
        insight: {
            query: gql`
                query($id: ID!) {
                    insight(id: $id) {
                        ${insightFields}
                    }
                }
            `,
            variables() {
                return {
                    id: this.insightId
                };
            },
            update(data) {
                if (data.insight) {
                    const insight = { ...data.insight };
                    insight.type = insight.type.toLowerCase();
                    insight.subjects = insight.subjects.edges.map(x => x.node);
                    insight.evaluations = insight.evaluations.edges.map(x => {
                        return {
                            id: x.node.id,
                            evaluation: x.node.evaluation,
                            created: x.node.created,
                            createdTeacher: x.node.createdTeacher
                        };
                    });
                    return insight;
                } else {
                    this.$emit("input", false);
                    return this.insight;
                }
            },
            skip() {
                return !this.value;
            }
        }
    },
    methods: {
        ...mapMutations("insightmodal", ["openModal"]),
        ...mapActions("message", ["showMessage"]),
        ...mapMutations("insightmodal", ["deletedInsight"]),
        confirmDelete() {
            if (
                confirm(
                    this.$gettext(
                        "Weet u zeker dat u dit inzicht wilt verwijderen?"
                    )
                )
            ) {
                this.loading++;
                this.$apollo
                    .mutate({
                        mutation: gql`
                            mutation deleteInsight($id: ID!) {
                                deleteInsight(input: { insight: $id }) {
                                    errors {
                                        field
                                        messages
                                    }
                                }
                            }
                        `,
                        variables: {
                            id: this.numericId
                        }
                    })
                    .then(data => {
                        let errors = [];
                        for (const k in data.data) {
                            if (
                                Object.prototype.hasOwnProperty.call(
                                    data.data,
                                    k
                                )
                            ) {
                                errors = errors.concat(
                                    data.data[k].errors || []
                                );
                            }
                        }

                        if (errors.length === 0) {
                            this.deletedInsight(this.insightId);
                            this.$emit("input", false);
                            this.showMessage({
                                message: this.$gettext(
                                    "Inzicht is succesvol verwijderd."
                                ),
                                type: "success"
                            });
                        } else {
                            this.formError();
                        }
                    })
                    .catch(err => {
                        this.formError(err);
                    })
                    .finally(() => {
                        this.loading--;
                    });
            }
        },
        evaluateInsight() {
            this.showEducationPlanFinishModal = true;
        },
        interimEvaluateInsight() {
            this.showEducationPlanInterimEvaluationModal = true;
        }
    }
};
</script>

<style lang="sass">
.insight-detail__header
    display: flex
    justify-content: space-between
    align-items: center
    flex-wrap: wrap
    gap: 16px

.insight-detail__important
    display: flex
    align-items: center
    gap: 4px
    padding: 10px 24px
    width: 100%

    color: $color-red
    font-weight: $strong-weight
    font-family: $primary-font-family
    @include font-size(13px, 16px)

    border-radius: 24px
    background-color: $color-red-light

.insight-detail__info
    display: flex
    flex-direction: column
    gap: 16px

.insight-detail__info-item
    display: flex
    flex-direction: column
    gap: 8px

.insight-detail__dates
    line-height: 1.5

.insight-detail__evaluations
    display: flex
    flex-direction: column
    gap: 24px

.insight-evaluation
    display: flex
    flex-direction: column
    padding: 16px

    color: $color-blue-shade-dark

    border-radius: 4px
    background-color: $color-blue-lightest
    border: 1px solid $color-blue-lighter

.insight-evaluation__footer
    margin-top: 16px
    padding-top: 16px

    border-top: 1px solid $color-blue-shade-light

.insight-evaluation__title
    font-size: 14px
    line-height: 1
    color: $color-blue
    font-weight: 700
</style>
