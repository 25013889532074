<template>
    <div :class="antlerClass()">
        <slot />
    </div>
</template>

<script>
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppStudentList",
    setup() {
        const { antlerClass } = antlerComponent("student-list");

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
.student-list
    .student-list__item
        border-bottom:
            width: 1px
            style: solid
            color: $color-blue-lighter

        &:last-child
            border-bottom: 0
</style>
