<template>
    <component :is="as" :class="antlerClass()">
        <slot />
    </component>
</template>

<script>
import AppIcon from "@/components/AppIcon";
import { antlerComponent } from "@/utils/antlerComponent";

export default {
    name: "AppText",
    components: { AppIcon },
    props: {
        as: {
            type: String,
            default: "span",
            validator: value => {
                return ["span", "div", "p"].includes(value);
            }
        },
        weight: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            default: ""
        },
        variant: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: ""
        }
    },
    setup(props) {
        const { antlerClass } = antlerComponent("text", props);

        return {
            antlerClass
        };
    }
};
</script>

<style lang="sass">
// weights
.text--weight-bold
    font-weight: bold

// variants
.text--date
    text-transform: capitalize

// sizes
.text--size-small
    @include font-size(14px)

// colors
.text--color-blue-shade-dark
    color: $color-blue-shade-dark
</style>
